/*global UAParser*/
$(document).ready(function() {
    //Just protecting the page..
    try {
        var code = 200;
        if (window.MAF_ERROR) {
            code = 500;
        }
        if (window.MAF_NOT_FOUND) {
            code = 404;
        }
        var parser = new UAParser();
        var browser = parser.getResult();
        delete browser.ua;
        var page = location.pathname + location.search;
        var ref = document.referrer.replace('https://', '').replace(location.host, '');
        var url = '/on/demandware.store/Sites-medicalert-Site/default/Account-MAFLogger';
        url += '?page=' + encodeURIComponent(page) + '&ref=' + encodeURIComponent(ref);
        url += '&browser=' + encodeURIComponent(JSON.stringify(browser));
        url += '&code=' + String(code);
        if (window.MAF_ERROR) {
            url += '&errorMessage=' + encodeURIComponent(window.MAF_ERROR);
        }
        $.ajax(url).then(function(res) {
            var logged = res.logged; // eslint-disable-line no-unused-vars
            if (window.MAFLogger && window.MAFLogger.callback) {
                window.MAFLogger.callback(res);
            }
        });
    } catch (e) {
        var logged = e; // eslint-disable-line no-unused-vars
        console.log(e);
    }
});
